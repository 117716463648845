import { Flex, Tabs, View } from '@aws-amplify/ui-react'
import * as React from 'react'
import { FaChevronLeft } from 'react-icons/fa6'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import { MachineEntry } from 'psi-lib'

import { getMachine } from '../api/MachinesApi'
import { getLinkToMachineFamilyImage, getLinkToMachines, getLinkToMaschine, getLinkToMaschineBaleStatsOverview, getLinkToMaschineDailyReportsOverview, getLinkToMaschineStoerungen } from '../LinkUtils'
import { getMachineFamily } from '../UtilsForPsiLib'

import './MachinePageWrapper.css'

export const MachinePageWrapper = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [machine, setMachine] = React.useState<MachineEntry | null>(null)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const params = useParams()
  const ext_sn = params.seriennummer

  React.useEffect(() => {
    if (!ext_sn) {
      setError("Ohne Maschinennummer können keine Maschinendaten geladen werden.")
      setLoading(false)
      return
    }
    getMachine(ext_sn)
      .then(m => {
        // console.log(m)
        setMachine(m)
        setError(undefined)
        setLoading(false)
      })
      .catch(err => {
        console.log('err:')
        console.log(err)
        setMachine(null)
        setError(err)
        setLoading(false)
      })
  }, [ext_sn])

  if (loading) {
    return <>Lade Maschinendaten, bitte warten...</>
  }
  if (error) {
    return <>Fehler: {error}</>
  }

  const loadedMachine = machine!

  const linkToMaschine = getLinkToMaschine(loadedMachine)
  const linkToMaschineStoerungen = getLinkToMaschineStoerungen(loadedMachine)
  const linkToMaschineDailyReports = getLinkToMaschineDailyReportsOverview(loadedMachine)
  {/** @todo fix link in another branch */ }
  const linkToMaschineMonthlyReports = `/maschinen/${loadedMachine.ext_sn}/fix-me-when-its-done`
  const linkToMaschineBaleReports = getLinkToMaschineBaleStatsOverview(loadedMachine)
  const tabNameOverview = 'overview'
  const tabNameErrors = 'errors'
  const tabNameDailyReports = 'daily-reports'
  const tabNameMonthlyReports = 'monthly-reports'
  const tabNameBaleReports = 'bale-reports'

  let tabName = tabNameOverview
  if (location.pathname.startsWith(linkToMaschine)) {
    tabName = tabNameOverview
  }
  if (location.pathname.startsWith(linkToMaschineStoerungen)) {
    tabName = tabNameErrors
  }
  if (location.pathname.startsWith(linkToMaschineDailyReports)) {
    tabName = tabNameDailyReports
  }
  if (location.pathname.startsWith(linkToMaschineMonthlyReports)) {
    tabName = tabNameMonthlyReports
  }
  if (location.pathname.startsWith(linkToMaschineBaleReports)) {
    tabName = tabNameBaleReports
  }

  const machineFamily = getMachineFamily(loadedMachine)
  return (
    <>
      <div className='machine-page-wrapper bg-white'>
        <div className='back-link-container'>
          <Link to={getLinkToMachines()}>
            <FaChevronLeft className='inline mr-4' /> Maschinenübersicht
          </Link>
        </div>

        <View className='machine-header'>
          <Flex alignItems='center'>
            <div>
              <img className="machine-image" src={getLinkToMachineFamilyImage(machineFamily)} />
            </div>
            <Flex direction='column' gap='0px'>
              <div className="machine-serial">{ext_sn}</div>
              <div className="machine-family">{machineFamily}</div>
            </Flex>
          </Flex>
        </View>

        <Tabs.Container defaultValue={tabName}>
          <Tabs.List>
            <Tabs.Item value={tabNameOverview} onClick={() => navigate(linkToMaschine)}>
              Übersicht
            </Tabs.Item>
            <Tabs.Item value={tabNameErrors} onClick={() => navigate(linkToMaschineStoerungen)}>
              Störungen
            </Tabs.Item>
            <Tabs.Item value={tabNameDailyReports} onClick={() => navigate(linkToMaschineDailyReports)}>
              Tagesberichte
            </Tabs.Item>
            <Tabs.Item value={tabNameMonthlyReports} onClick={() => navigate(linkToMaschineMonthlyReports)}>
              Monatsberichte
            </Tabs.Item>
            <Tabs.Item value={tabNameBaleReports} onClick={() => navigate(linkToMaschineBaleReports)}>
              Ballenberichte
            </Tabs.Item>
          </Tabs.List>
        </Tabs.Container>
      </div>

      {/* TODO
      <ul>
        <li>Remove css-fixed Search ; Add css-"normal" "back to list" link. What?</li>
        <li>Remove Breadcrumbs -- TBD on each page</li>
      </ul> */}

      <main className='p-8'>
        <Outlet />
      </main>
    </>
  )
}
