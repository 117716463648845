import { FaChevronRight } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

import { MachineEntry } from 'psi-lib'
import ConnectivityIndicator from './ConnectivityIndicator'
import { getLinkToMachineFamilyImage, getLinkToMaschine } from '../LinkUtils'
import { getMachineFamily } from '../UtilsForPsiLib'
import { Flex } from '@aws-amplify/ui-react'

interface MachinesListItemProps {
  machine: MachineEntry
}
export default function MachinesListItem({ machine, ...props }: MachinesListItemProps) {
  // console.log('machine', machine)
  const navigate = useNavigate()
  const machineFamily = getMachineFamily(machine)
  return (
    <tr className='machines-list-item' onClick={() => navigate(getLinkToMaschine(machine))}>
      <td className='py-4'>
        <Flex alignItems='center'>
          <img className="w-6 h-6 rounded-full border border-[#c8cdda]" src={getLinkToMachineFamilyImage(machineFamily)} />
          <div className='font-semibold leading-normal'>{machine.ext_sn}</div>
        </Flex>
      </td>
      <td>{machineFamily}</td>
      <td>{machine.adresse.bezeichnung}</td>
      <td>
        {machine.adresse.strasse}
        {' '}
        {machine.adresse.hausnummer},
        {' '}
        {machine.adresse.plz}
        {' '}
        {machine.adresse.ort}
      </td>
      <td>
        {machine.presence
          ? <>
            <ConnectivityIndicator name="Sensor-Daten" isConnected={machine.presence.isConnected} isDisabled={['ausgebaut', 'Dataplane deaktiviert'].includes(machine.machine_status)} />
            {' '}
            <ConnectivityIndicator name="Fernwartung" isConnected={machine.presence.isAjaConnected} isDisabled={['ausgebaut'].includes(machine.machine_status)} />
          </>
          : 'Verbindung: n/a'
        }
      </td>
      <td>
        <FaChevronRight />
      </td>
    </tr>
  )
}
