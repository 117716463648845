import { Flex, View } from '@aws-amplify/ui-react'
import * as React from 'react'
import { FaRegCalendarDays } from 'react-icons/fa6'
import { useSearchParams } from 'react-router-dom'

import { MachineEntry } from 'psi-lib'

import { filterMachines, getMachines, sortMachines } from '../api/MachinesApi'
import { ButtonWithIcon } from '../components/ButtonWithIcon'
import MachinesTable from '../components/MachinesTable'
import { RoundedCard } from '../components/RoundedCard'

import './maschinen.css'

export default function Homepage() {
  const [machines, setMachines] = React.useState<MachineEntry[] | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [searchParams, setSearchParams] = useSearchParams()

  React.useEffect(() => {
    getMachines()
      .then(res => {
        if (!res) {
          throw new Error("No machines found.")
        }
        const items: MachineEntry[] = Object.values(res) as MachineEntry[]
        const filter = searchParams.get('filter')?.toLocaleLowerCase()
        const machinesFiltered = filterMachines(items, filter)
        const machinesSorted = sortMachines(machinesFiltered)
        setMachines(machinesSorted)
        setError(undefined)
        setLoading(false)
      })
      .catch(err => {
        setMachines([])
        console.error('Error fetching data from api')
        console.log(err)
        setError(err)
        setLoading(false)
      })
  }, [searchParams])

  if (loading) {
    return <>Lade Maschinendaten, bitte warten...</>
  }
  if (error) {
    return <>Fehler: {error}</>
  }

  const loadedMachines = machines!

  return (
    <main className='p-8'>
      <div className='page-heading mb-12'>
        Guten Morgen Hannes
        {/** @todo: Grußformel dynamisch */}
        {/** @todo: Username dynamisch */}
      </div>

      <View className='mb-4'>
        <Flex alignItems='center' justifyContent='space-between'>
          <div className='section-heading !mb-0'>
            Übersicht
          </div>
          <ButtonWithIcon
            title="Letzte Woche"
            icon={<FaRegCalendarDays className='icon inline' />} />
        </Flex>
      </View>

      <View className='mb-3'>
        <Flex>
          <RoundedCard className='w-1/4'>
            <div className='card-heading'>
              Lorem Ipsum
            </div>
          </RoundedCard>
          <RoundedCard className='w-1/4'>
            <div className='card-heading'>
              Dolore set
            </div>
          </RoundedCard>
          <RoundedCard className='w-1/4'>
            <div className='card-heading'>
              Amet ipsum
            </div>
          </RoundedCard>
          <RoundedCard className='w-1/4'>
            <div className='card-heading'>
              Lorem Ipsum
            </div>
          </RoundedCard>
        </Flex>
      </View>

      <RoundedCard>
        <div className='section-heading'>Meine Maschinen</div>
        <MachinesTable machines={loadedMachines} />
      </RoundedCard>
    </main>
  )
}
