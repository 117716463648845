import { MachineEntry } from 'psi-lib'

interface StandortDetailsProps {
  maschine: MachineEntry
  className?: string
}
export default function StandortDetails({ maschine, className }: StandortDetailsProps) {
  return (
    <div className={`w-1/2 ${className}`}>
      <div className='card-heading'>Standort</div>
      {maschine.adresse
        ? (
          <div>
            <strong>{maschine.adresse.bezeichnung}</strong><br />
            {maschine.adresse.strasse} {maschine.adresse.hausnummer}<br />
            {maschine.adresse.plz} {maschine.adresse.ort}<br />
          </div>
        )
        : 'unbekannt'}
    </div>
  )
}
