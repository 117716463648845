import { MachineEntry } from 'psi-lib'

interface VerbindungsDetailsProps {
  maschine: MachineEntry
  [key: string]: any
}
export default function VerbindungsDetails({ maschine }: VerbindungsDetailsProps) {
  let isDisabled = false
  if (!maschine.presence
    || (!maschine.presence.connected && !maschine.presence.disconnected)
    || ['ausgebaut', 'Dataplane deaktiviert'].includes(maschine.machine_status)) {
    isDisabled = true
  }
  let isConnected = maschine.presence?.isConnected || false
  const connectedDate = isConnected
    ? maschine.presence?.connected?.dt
    : maschine.presence?.disconnected?.dt
  if (!connectedDate) {
    isDisabled = true
  }
  if (isDisabled) {
    isConnected = false
  }

  let isAjaDisabled = false
  if (!maschine.presence
    || (!maschine.presence['aja-connected'] && !maschine.presence['aja-disconnected'])
    || ['ausgebaut'].includes(maschine.machine_status)) {
    isAjaDisabled = true
  }
  let isAjaConnected = maschine.presence?.isAjaConnected || false
  let ajaConnectedDate = undefined
  if (maschine.presence) {
    ajaConnectedDate = isAjaConnected
      ? (maschine.presence['aja-connected']?.dt)
      : (maschine.presence['aja-disconnected']?.dt)
  }
  if (!ajaConnectedDate) {
    isAjaDisabled = true
  }
  if (isAjaDisabled) {
    isAjaConnected = false
  }

  return (
    <div>
      <div className='card-heading'>Verbindungen</div>
      <table className='row-headers w-full'>
        <tbody>
          {maschine.machine_status && maschine.machine_status !== 'n/a' && maschine.machine_status !== 'aktiv'
            && (
              <tr>
                <th scope='row' className='w-1/2'>
                  Status:
                </th>
                <td>
                  {maschine.machine_status.replace('Dataplane', 'Sensor-Daten')}
                </td>
              </tr>
            )
          }
          <tr>
            <th scope='row' className='w-1/2'>
              Sensor-Daten:
            </th>
            <td>
              {maschine.presence
                ? (
                  <TextWithTooltip tooltip={'seit ' + connectedDate}>seit {formatDateTimeSince(connectedDate!)}
                    {' '}
                    {isConnected
                      ? 'verbunden'
                      : 'getrennt'}
                  </TextWithTooltip>
                )
                : 'unbekannt'}
            </td>
          </tr>
          <tr>
            <th scope='row'>
              Fernwartung:
            </th>
            <td>
              {maschine.presence
                ? (
                  <TextWithTooltip tooltip={'seit ' + ajaConnectedDate}>seit {formatDateTimeSince(ajaConnectedDate!)}
                    {' '}
                    {isAjaConnected
                      ? 'verbunden'
                      : 'getrennt'}
                  </TextWithTooltip>
                )
                : 'unbekannt'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

interface TextWithTooltipProps {
  children: any
  tooltip: string
  [key: string]: any
}
function TextWithTooltip({ children, tooltip }: TextWithTooltipProps) {
  return (
    <span title={tooltip} className='psi-text-with-tooltip'>{children}</span>
  )
}

/** Returns duration since a past datetime as human-readable (German) string, using only the most significant unit (e.g., 1 year). */
function formatDateTimeSince(dt: string) {
  const date = new Date(dt);
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " Jahren";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " Monaten";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " Tagen";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " Stunden";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " Minuten";
  }
  return Math.floor(seconds) + " Sekunden";
}
