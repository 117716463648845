import { Flex } from '@aws-amplify/ui-react'
import { useState } from 'react'

import './ButtonWithIcon.css'

interface ButtonWithIconProps {
  title: string
  icon: React.ReactNode
  className?: string
}
export const ButtonWithIcon = ({ title, className, icon }: ButtonWithIconProps) => {
  return (
    <Flex alignItems='center' className={`button-with-icon ${className || ''}`}>
      {title}
      {icon}
    </Flex>
  )
}

interface SelectButtonWithIconProps {
  options: {
    value: string
    text: string
  }[]
  selected?: string
  icon: React.ReactNode
  onChange?: (selectedItem: string) => void
  className?: string
}
export const SelectButtonWithIcon = ({ options, selected, className, onChange, icon }: SelectButtonWithIconProps) => {
  const [selectedOption, setSelectedOption] = useState<string>(selected || '')
  return (
    <Flex alignItems='center' className={`button-with-icon select-button-with-icon ${className || ''}`}>
      <select
        value={selectedOption}
        onChange={e => {
          setSelectedOption(e.target.value)
          if (onChange) {
            onChange(e.target.value)
          }
        }}>
        {options.map(o => <option value={o.value}>{o.text}</option>)}
      </select>
      {icon}
    </Flex>
  )
}