import { SearchField } from '@aws-amplify/ui-react'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

export const SearchField2 = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [value, setValue] = React.useState('')

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const filter = event.target.value;
    setValue(filter)
    if (filter) {
      setSearchParams({ filter }, { replace: true });
    } else {
      setSearchParams({}, { replace: true });
    }
  }

  // It is your responsibility to set up onClear
  const onClear = () => {
    setValue('')
  }

  return (
    <SearchField
      label="Maschinensuche"
      placeholder='Suche...'
      hasSearchButton={false}
      hasSearchIcon={true}
      onChange={onChange}
      onClear={onClear}
      value={value}
    />
  )
}