import { useAuthenticator } from '@aws-amplify/ui-react'
import { FaArrowUpRightFromSquare, FaCartArrowDown, FaChevronDown, FaEnvelope, FaHouse, FaLifeRing, FaRegCircleQuestion, FaSliders, FaUser } from 'react-icons/fa6'
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar'
import { NavLink } from 'react-router-dom'

import './MainMenu.css'

const MainMenu = () => {
  const { user, signOut } = useAuthenticator(context => [context.user, context.signOut])
  return <Sidebar id="main-menu"
    className='!border-r !border-[#d9d9d9]'>
    <Menu renderExpandIcon={() => <></>}
      className='p-2 pt-8'>
      <MenuItem icon={<FaHouse />} component={<NavLink to={`/`} end />}>Dashboard</MenuItem>
      <SubMenu icon={<></>} label="Maschinen" component={<NavLink to="/maschinen/" end />} defaultOpen open={true}>
        <MenuItem icon={<></>} component={<NavLink to={`/maschinen/?machineFamily=AutoLoadBaler`} end />}>AutoLoadBaler</MenuItem>
        <MenuItem icon={<></>} component={<NavLink to={`/maschinen/?machineFamily=JumboLoadBaler`} end />}>JumboLoadBaler</MenuItem>
      </SubMenu>
      <MenuItem icon={<FaSliders />} component={<NavLink to="/settings/" />}>Einstellungen</MenuItem>
      {/* <MenuItem icon={<></>} component={<NavLink to="https://www.strautmann-umwelt.com/" />}>Shop <FaArrowUpRightFromSquare className='inline pl-1' /></MenuItem> */}
      <MenuItem icon={<></>} component={<NavLink to="https://www.strautmann-umwelt.com/produkte/verbrauchsmaterial/" />}>Verbrauchsmaterial <FaArrowUpRightFromSquare className='inline pl-1' /></MenuItem>
      <MenuItem icon={<></>} component={<NavLink to="/admin/" />}>Admin</MenuItem>
      <MenuItem icon={<FaCartArrowDown />} component={<NavLink to="https://docs.google.com/forms/d/e/1FAIpQLSePcxn7H145l-L3v3yMyv_nutLcZu-es03yrB8jsnRWkEmhEw/viewform?usp=sf_link" target='_blank' />}>
        Modem bestellen <FaArrowUpRightFromSquare className='inline pl-1' />
      </MenuItem>
    </Menu>

    <div className='absolute w-full bottom-44'>
      Weitere Links
      <Menu className='p-2'>
        {/* <MenuItem icon={<FaLifeRing />} component={<NavLink to="https://www.strautmann-umwelt.com/" />}>Support & Hilfe <FaArrowUpRightFromSquare className='inline pl-1' /></MenuItem> */}
        <MenuItem icon={<FaRegCircleQuestion />} component={<NavLink to="https://www.strautmann-umwelt.com/" />}>FAQ - Häufige Fragen <FaArrowUpRightFromSquare className='inline pl-1' /></MenuItem>
        <MenuItem icon={<FaEnvelope />} component={<NavLink to="https://www.strautmann-umwelt.com/kontakt/" />}>Kontakt <FaArrowUpRightFromSquare className='inline pl-1' /></MenuItem>
      </Menu>
    </div>

    <div className='absolute w-full bottom-16'>
      <Menu className='border-t border-[#d9d9d9] p-2'>
        <MenuItem icon={<FaUser />}>
          <div className='user-account' onClick={signOut}>
            {user
              ? user!.signInDetails?.loginId + ' '
              : ''}
          </div>
        </MenuItem>
        <MenuItem icon={<></>}>
          <button onClick={signOut}>Abmelden</button>
        </MenuItem>
      </Menu>
    </div>
  </Sidebar>
}

export default MainMenu
