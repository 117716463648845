import { Flex, View } from '@aws-amplify/ui-react'
import * as React from 'react'
import { FaCircleCheck, FaRegCircle } from 'react-icons/fa6'
import { useSearchParams } from 'react-router-dom'

import { MachineEntry } from 'psi-lib'

import { filterMachines, getMachines, sortMachines } from '../api/MachinesApi'
import MachinesTable from '../components/MachinesTable'
import { RoundedCard } from '../components/RoundedCard'
import { getLinkToMachineFamilyImage } from '../LinkUtils'
import { getMachineFamily } from '../UtilsForPsiLib'

import './maschinen.css'

export default function Maschinen() {
  const [machines, setMachines] = React.useState<MachineEntry[] | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedMachineFamily, setSelectedMachineFamily] = React.useState<string | undefined>(searchParams.get('machineFamily') || undefined)

  React.useEffect(() => {
    setSelectedMachineFamily(searchParams.get('machineFamily') || undefined)
    getMachines()
      .then(res => {
        if (!res) {
          throw new Error("No machines found.")
        }
        const items: MachineEntry[] = Object.values(res) as MachineEntry[]
        const filter = searchParams.get('filter')?.toLocaleLowerCase()
        const machinesFiltered = filterMachines(items, filter)
        const machineFamily = searchParams.get('machineFamily')
        const machinesFilteredFamily = machineFamily
          ? machinesFiltered.filter(m => {
            const mf = getMachineFamily(m)
            return mf === machineFamily
          })
          : machinesFiltered
        const machinesSorted = sortMachines(machinesFilteredFamily)
        setMachines(machinesSorted)
        setError(undefined)
        setLoading(false)
      })
      .catch(err => {
        setMachines([])
        console.error('Error fetching data from api')
        console.log(err)
        setError(err)
        setLoading(false)
      })
  }, [searchParams])

  if (loading) {
    return <>Lade Maschinendaten, bitte warten...</>
  }
  if (error) {
    return <>Fehler: {error}</>
  }

  const loadedMachines = machines!

  return (
    <main className='p-8'>
      <div className='page-heading mb-6'>
        Maschinentyp wählen
      </div>

      <View className='mb-8'>
        <Flex>
          <MachineFamilySelector machineFamilies={['AutoLoadBaler', 'JumboLoadBaler']} selectedItem={selectedMachineFamily} onSelectedChanged={(selectedItem: string | undefined) => {
            const machineFamily = selectedItem
            setSelectedMachineFamily(selectedItem)
            if (machineFamily) {
              setSearchParams({ machineFamily }, { replace: true });
            } else {
              setSearchParams({}, { replace: true });
            }
          }} />
        </Flex>
      </View>
      <RoundedCard>
        <div className='card-heading'>Meine Maschinen</div>
        <MachinesTable machines={loadedMachines} />
      </RoundedCard>
    </main>
  )
}

interface MachineFamilySelectorItemProps {
  machineFamily: string
  selectFunc: (item: string | undefined) => void
  selected?: boolean
}
const MachineFamilySelectorItem = ({ machineFamily, selectFunc, selected = false }: MachineFamilySelectorItemProps) => {
  return (
    <div className="w-[368px] h-[140px] relative cursor-pointer" onClick={() => { selectFunc(machineFamily) }}>
      <View className="w-full h-16 top-[76px] absolute bg-white rounded-lg shadow">
        <Flex alignItems='center' className="!h-16">
          <div className="ml-[138px] text-[#0a0f19] text-lg font-semibold font-['Plus Jakarta Sans'] leading-tight tracking-tight">{machineFamily}</div>
          {selected
            ? <FaCircleCheck className='text-su-primary-900 h-5 w-5 right-6 absolute' />
            : <FaRegCircle className='text-su-gray-500 h-5 w-5 right-6 absolute' />}
        </Flex>
      </View>
      {selected
        ? <img className="w-32 h-32 left-2 top-0 absolute" src={getLinkToMachineFamilyImage(machineFamily)} />
        : <img className="w-32 h-32 left-2 top-0 absolute opacity-80" style={{filter: 'grayscale(1) brightness(120%) contrast(45%)'}} src={getLinkToMachineFamilyImage(machineFamily)} />}
    </div>
  )
}

interface MachineFamilySelectorProps {
  machineFamilies: string[]
  onSelectedChanged: (item: string | undefined) => void
  selectedItem?: string
}
const MachineFamilySelector = ({ machineFamilies, onSelectedChanged, selectedItem }: MachineFamilySelectorProps) => {
  const select = (item: string | undefined) => {
    if (item === selectedItem) {
      item = undefined
    }
    onSelectedChanged(item)
  }
  return machineFamilies.map(mf => {
    return <MachineFamilySelectorItem machineFamily={mf} selected={!selectedItem || mf === selectedItem} selectFunc={select} />
  })
}
