import { Flex } from '@aws-amplify/ui-react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from "chart.js"
import 'chartjs-adapter-luxon'
import * as React from 'react'
import { Bar } from "react-chartjs-2"
import { FaDownload, FaRegCalendarDays } from 'react-icons/fa6'
import { useParams } from 'react-router-dom'

import { ErrorEntry, MachineEntry } from 'psi-lib'

import { getMachine } from '../api/MachinesApi'
import { ButtonWithIcon } from '../components/ButtonWithIcon'
import Fehlerliste from '../components/maschinen-details/Fehlerliste'
import { RoundedCard } from '../components/RoundedCard'

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
)

interface StoerungenChartJsProps {
  data: ErrorEntry[]
}
function StoerungenChartJs({ data }: StoerungenChartJsProps) {
  if (!data || data.length === 0) {
    return (
      <div>
        <strong>Störungsfrei</strong><br />
        <div>Für diese Maschine liegen keine Störungen vor.</div>
      </div>
    )
  }

  const errorHisto: Map<string, number> = new Map()
  data.forEach((e) => {
    const oldValue = errorHisto.get(e.eventName) || 0
    errorHisto.set(e.eventName, oldValue + 1)
  })
  const cdata = {
    labels: errorHisto.keys().toArray(),
    datasets: [
      {
        data: errorHisto.values().toArray(),
        borderWidth: 1,
        borderColor: '#164194',
        backgroundColor: '#164194'
      },
    ]
  }

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false }
    },
  }

  return <div><Bar className='stoerungen-chart' data={cdata} options={options} /></div>
}

export default function AlleStoerungen() {
  const [maschine, setMaschine] = React.useState<MachineEntry | null>(null)
  const params = useParams<{ seriennummer: string | undefined }>()
  const ext_sn = params.seriennummer

  React.useEffect(() => {
    if (!ext_sn) {
      return
    }
    getMachine(ext_sn)
      .then(m => {
        // console.log(m)
        setMaschine(m)
      })
      .catch(err => {
        console.error('Error:', err)
        setMaschine(null)
      })
  }, [ext_sn])

  if (!maschine) {
    return <div>Fehler beim Laden der Störungen. Maschine konnte nicht geladen werden.</div>
  }

  return (
    <Flex>
      <RoundedCard className='w-2/3'>
        <Flex className='!mb-6' alignItems='center' justifyContent='space-between'>
          <div className='section-heading !mb-0'>Störungen</div>
          <ButtonWithIcon
            className='!ml-auto'
            title="Download Excel"
            icon={<FaDownload />} />
          <ButtonWithIcon
            title="13.07. - 12.08.2024"
            icon={<FaRegCalendarDays />} />
        </Flex>

        <Fehlerliste maschine={maschine} maxDisplayLength={0} />
      </RoundedCard>

      <RoundedCard className='w-1/3'>
        <div className='section-heading !mb-6'>Diagramm Störungen</div>
        <StoerungenChartJs data={maschine.errors} />
      </RoundedCard>
    </Flex>
  )
}
