import { Badge } from "@aws-amplify/ui-react"
import { MachineEntry } from 'psi-lib'
import './ModemDetails.css'

type ConnectivityStatus = 'unknown' | 'disabled' | 'online' | 'offline'

interface ModemDetailsProps {
  machine: MachineEntry
}
export const ModemDetails = ({ machine }: ModemDetailsProps) => {
  const modem_sn = machine.modem_sn || machine.seriennummer
  const sensorIsDisabled = ['ausgebaut', 'Dataplane deaktiviert'].includes(machine.machine_status)
  const remoteIsDisabled = ['ausgebaut'].includes(machine.machine_status)
  let sensorClass: ConnectivityStatus
  if (sensorIsDisabled
    || machine.presence.isConnected === "unknown") {
    sensorClass = "disabled"
  } else {
    sensorClass = machine.presence.isConnected ? 'online' : 'offline'
  }
  let remoteClass: ConnectivityStatus
  if (remoteIsDisabled
    || machine.presence.isAjaConnected === "unknown") {
    remoteClass = "disabled"
  } else {
    remoteClass = machine.presence.isAjaConnected ? 'online' : 'offline'
  }
  return (
    <div>
      <div className="float-right">
        <Badge size="small" className={`badge ${sensorClass}`}>Sensor</Badge>
        {' '}
        <Badge size="small" className={`badge ${remoteClass}`}>Fernwartung</Badge>
      </div>
      <div className='card-heading'>Modem</div>
      <table className="row-headers w-full">
        <tbody>
          {modem_sn && (
            <tr>
              <th scope="row">
                Seriennummer:
              </th>
              <td>
                {modem_sn}
              </td>
            </tr>
          )}
          <tr>
            <th scope="row" className="w-1/2">
              Einrichtung am:
            </th>
            <td>
              {machine.einrichtungAm || 'unbekannt'}
            </td>
          </tr>
          <tr>
            <th scope="row">
            Inbetriebnahme Modem:
            </th>
            <td>
            {machine.inbetriebnahmeModem || <i>Daten nicht gepflegt</i>}
            </td>
          </tr>           
          <tr>
            <th scope="row">
              Letztes Update am:
            </th>
            <td>
              {machine.letztesUpdateAm || 'unbekannt'}
            </td>
          </tr>
          <tr>
            <th scope="row">
              Firmware Versionen:
            </th>
            <td>
              {machine.firmwareVersion
                ? <span>
                  {machine.firmwareVersion.flows || 'unbekannt'} # {machine.firmwareVersion['aws-jobs-agent'] || 'unbekannt'}
                </span>
                : 'unbekannt'
              }
            </td>
          </tr>
          <tr>
            <th scope="row">
              OS Version:
            </th>
            <td>
              {machine.os_release && machine.os_release.pretty_name || 'unbekannt'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
