import { Flex } from '@aws-amplify/ui-react'
import { useEffect, useState } from 'react'
import { FaBox, FaDownload } from 'react-icons/fa6'
import { useParams } from 'react-router-dom'

import { BaleReportDto } from 'psi-lib'

import { getBaleReport } from '../api/MachinesApi'
import { BaleStatsTable } from '../components/BaleStatsTable'
import { ButtonWithIcon } from '../components/ButtonWithIcon'
import { RoundedCard } from '../components/RoundedCard'

export default function BaleStats() {
  const params = useParams()
  const ext_sn = params.seriennummer

  const [reportData, setReportData] = useState<BaleReportDto | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!ext_sn) {
      setError("Ohne Maschinennummer können keine Maschinendaten geladen werden.")
      setLoading(false)
      return
    }

    const fetchReportData = async (ext_sn: string, params: any) => {
      const baleReportId = params.baleStatsId!
      try {
        const baleReport = await getBaleReport(ext_sn, baleReportId)
        if (!baleReport || !baleReport.ballennummer) {
          throw new Error(`Keine Daten für ${ext_sn} und ${baleReportId} gefunden.`)
        }
        console.log('baleReport', baleReport)
        setReportData(baleReport)
        setError(undefined)
      } catch (err) {
        console.error('Error:', err)
        setReportData(undefined)
        if (err instanceof Error) {
          setError(err.message)
        } else {
          setError((err as any).toString())
        }
      } finally {
        setLoading(false)
      }
    }
    fetchReportData(ext_sn, params)
  }, [ext_sn, params])

  let content
  if (loading) {
    content = <>
      <br />
      Lade Daten...
    </>
  } else if (error) {
    content = <>
      <br />
      <div>{`Fehler beim Laden der Daten - ${error}`}</div>
    </>
  } else if (!reportData) {
    content = <>
      <br />
      <div>{`Fehler beim Laden der Daten - leer`}</div>
    </>
  } else {
    content = <>
      {/* <ReportHeader maschine={maschineData} />
      <br /> */}
      <BaleStatsTable baleStats={reportData} />
    </>
  }

  if (!ext_sn) {
    return <div>Fehler beim Laden des Ballenberichts. Keine Maschinennummer angegeben.</div>
  }

  if (!reportData) {
    return <div>Fehler beim Laden des Ballenberichts. Keine Daten gefunden.</div>
  }

  return (
    <RoundedCard>
      <Flex className='!mb-6' alignItems='center' justifyContent='space-between'>
        <div className='section-heading !mb-0'>Ballen {reportData.ballennummer}</div>
        <div className='spacer ml-auto' />
        <ButtonWithIcon
          title="Download Excel"
          icon={<FaDownload />} />
        <ButtonWithIcon
          title={`# ${reportData.ballennummer}`}
          icon={<FaBox />} />
        {/* icon should be the non-free BoxesStacked */}
      </Flex>
      {content}
    </RoundedCard>
  )
}
