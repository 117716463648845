import MachinesListItem from './MachinesListItem'
import MaschinenLink from './maschinen-liste/MaschinenLink'

interface MachinesTableProps {
  machines: any[]
}
export default function MachinesTable({ machines, ...props }: MachinesTableProps) {
  if (!machines) {
    return (
      <div className='psi-search-result-info'>
        Lade Daten...
      </div>
    )
  }
  if (machines.length === 0) {
    return (
      <div className='psi-search-result-info'>
        {machines.length === 0
          ? 'Keine Daten.'
          : 'Keine Treffer.'}
      </div>
    )
  }
  return (
    <table className='w-full'>
      <thead>
        <tr>
          <th>Seriennummer</th>
          <th>Typ</th>
          <th>Kunde</th>
          <th>Standort</th>
          <th>Status</th>
          <th>{/* right-arrow */}</th>
        </tr>
      </thead>
      <tbody>
        {machines.map((machine) => (
          <MachinesListItem key={machine.ext_sn} machine={machine} />
        ))}
      </tbody>
    </table>
  )
}
