import { AuthTokens, fetchAuthSession, getCurrentUser } from 'aws-amplify/auth'
import { Cache } from 'aws-amplify/utils'
import { isProd } from './config'

/** @todo: Move to strtmn */

export const currentSession = async (): Promise<AuthTokens | undefined> => {
  try {
    return (await fetchAuthSession()).tokens ?? undefined
  } catch (err) {
    console.log(err)
  }
}

export const isUserSignedIn = async () => {
  // Only load if the user is signed in.
  try {
    const currentUser = await getCurrentUser()
    // console.log("  got signed in user:", currentUser)
  } catch (err) {
    if (isProd) {
      // console.log("  No signed in user.")
    } else {
      // console.log("  No signed in user:", err)
      console.log("  DEV: clearing cache")
      Cache.clear()
    }
    return false
  }
}
