import { Cache } from 'aws-amplify/utils'

import { BaleReportDto, DailyReportResponseDto, MachineEntry, RecentDailyReportsResponse } from 'psi-lib'

import { isUserSignedIn } from "../Utils.AmplifyAuth"

import { fetchFromApi, getCacheKey } from "./utils"

// 10 minutes
const EXPIRES_IN_MS = 10 * 60 * 1000

export const getMachines = async (): Promise<{
  [key: string]: MachineEntry
} | null> => {
  console.log("> getMachines")
  const apiName = 'maschinenApi'
  const path = '/maschinen'
  const cacheKey = getCacheKey(apiName, path)

  if (!isUserSignedIn()) {
    return null
  }

  try {
    // console.log('  try to get item from cache')
    // console.warn('  remove item from cache') // DBG
    // await Cache.removeItem(cacheKey) // DBG
    let data = await Cache.getItem(cacheKey)
    if (data) {
      console.log('Found in cache:', data, `${cacheKey}`)
    } else {
      console.log(cacheKey, 'not found in cache. Loading from API...')
      data = await fetchFromApi(apiName, path)
      await Cache.setItem(cacheKey, data, { expires: new Date().getTime() + EXPIRES_IN_MS })
      console.log('Fetched from api:', data)
    }
    // console.log("✅ getMachines: data")
    return await data
  }
  catch (err) {
    console.error('Error fetching Machines data from cache (unlikely) or api (likely).')
    console.log("api name =", apiName)
    console.log('Error:', err)
    // console.log("❌ getMachines: throw")
    throw err
  }
}

export const getMachine = async (ext_sn: string): Promise<MachineEntry | null> => {
  console.log("> getMachine")
  const apiName = 'maschinenApi'
  const path = `/maschine/${ext_sn}`
  const cacheKey = getCacheKey(apiName, path)

  if (!isUserSignedIn()) {
    return null
  }

  try {
    // console.log('  try to get item from cache')
    // console.warn('  remove item from cache') // DBG
    // await Cache.removeItem(cacheKey) // DBG
    let data: MachineEntry | null = await Cache.getItem(cacheKey)
    if (data) {
      console.log('Found in cache:', data, `${cacheKey}`)
    } else {
      console.log(cacheKey, 'not found in cache. Loading from API...')
      data = await fetchFromApi(apiName, path) as MachineEntry
      data.errors.forEach((e) => {
        e.eventName = e.eventName
          .replace("Err_", "")
          .replace('Blockiet', 'Blockiert')
      })
      await Cache.setItem(cacheKey, data, { expires: new Date().getTime() + EXPIRES_IN_MS })
      console.log('Fetched from api:', data)
    }
    // console.log("✅ getMachine: data")
    return data
  }
  catch (err) {
    console.error('Error fetching Machine data from cache (unlikely) or api (likely).')
    console.log("api name =", apiName)
    console.log('Error:', err)
    // console.log("❌ getMachine: throw")
    throw err
  }
}

export const getRecentDailyReports = async (ext_sn: string): Promise<RecentDailyReportsResponse | null> => {
  console.log("> getRecentDailyReports")
  const apiName = 'maschinenApi'
  const path = `/maschine/${ext_sn}/reports/daily`
  const cacheKey = getCacheKey(apiName, path)

  if (!isUserSignedIn()) {
    return null
  }

  try {
    // console.log('  try to get item from cache')
    // console.warn('  remove item from cache') // DBG
    // await Cache.removeItem(cacheKey) // DBG
    let data: RecentDailyReportsResponse | null = await Cache.getItem(cacheKey)
    if (data) {
      console.log('Found in cache:', data, `${cacheKey}`)
    } else {
      console.log(cacheKey, 'not found in cache. Loading from API...')
      data = await fetchFromApi(apiName, path) as RecentDailyReportsResponse
      if (!data || Array.isArray(data) || !data.latest) {
        throw new Error(`Keine Daten für ${ext_sn} gefunden.`)
      }
      await Cache.setItem(cacheKey, data, { expires: new Date().getTime() + EXPIRES_IN_MS })
      console.log('Fetched from api:', data)
    }
    // console.log("✅ getRecentDailyReports: data")
    return data
  }
  catch (err) {
    console.error('Error fetching recent daily reports from cache (unlikely) or api (likely).')
    console.log("api name =", apiName)
    console.log('Error:', err)
    // console.log("❌ getRecentDailyReports: throw")
    throw err
  }
}

export const getDailyReport = async (ext_sn: string, dateOfReport?: string): Promise<DailyReportResponseDto | null> => {
  console.log("> getDailyReport")
  const apiName = 'maschinenApi'
  const path = `/maschine/${ext_sn}/reports/daily/${dateOfReport}`
  const cacheKey = getCacheKey(apiName, path)

  if (!isUserSignedIn()) {
    return null
  }

  try {
    // console.log('  try to get item from cache')
    // console.warn('  remove item from cache') // DBG
    // await Cache.removeItem(cacheKey) // DBG
    let data: DailyReportResponseDto | null = await Cache.getItem(cacheKey)
    if (data) {
      console.log('Found in cache:', data, `${cacheKey}`)
    } else {
      console.log(cacheKey, 'not found in cache. Loading from API...')
      data = await fetchFromApi(apiName, path) as DailyReportResponseDto
      if (!data || !Array.isArray(data.rows) || 0 === data.rows.length) {
        throw new Error(`Keine Daten für ${ext_sn} und ${dateOfReport} gefunden.`)
      }
      await Cache.setItem(cacheKey, data, { expires: new Date().getTime() + EXPIRES_IN_MS })
      console.log('Fetched from api:', data)
    }
    // console.log("✅ getDailyReport: data")
    return data
  }
  catch (err) {
    console.error('Error fetching recent daily reports from cache (unlikely) or api (likely).')
    console.log("api name =", apiName)
    console.log('Error:', err)
    // console.log("❌ getDailyReport: throw")
    throw err
  }
}

export const getBaleReport = async (ext_sn: string, baleReportId: string): Promise<BaleReportDto | null> => {
  console.log("> getBaleReport")
  const apiName = 'maschinenApi'
  const path = `/maschine/${ext_sn}/reports/bales/${baleReportId}`
  const cacheKey = getCacheKey(apiName, path)

  if (!isUserSignedIn()) {
    return null
  }

  try {
    // console.log('  try to get item from cache')
    // console.warn('  remove item from cache') // DBG
    // await Cache.removeItem(cacheKey) // DBG
    let data: BaleReportDto | null = await Cache.getItem(cacheKey)
    if (data) {
      console.log('Found in cache:', data, `${cacheKey}`)
    } else {
      console.log(cacheKey, 'not found in cache. Loading from API...')
      data = await fetchFromApi(apiName, path) as BaleReportDto
      if (!data || !data.ballennummer) {
        throw new Error(`Keine Daten für ${ext_sn} und ${baleReportId} gefunden.`)
      }
      await Cache.setItem(cacheKey, data, { expires: new Date().getTime() + EXPIRES_IN_MS })
      console.log('Fetched from api:', data)
    }
    // console.log("✅ getBaleReport: data")
    return data
  }
  catch (err) {
    console.error('Error fetching recent daily reports from cache (unlikely) or api (likely).')
    console.log("api name =", apiName)
    console.log('Error:', err)
    // console.log("❌ getBaleReport: throw")
    throw err
  }
}

export const sortMachines = (machines: MachineEntry[]) => {
  console.log("> sortMachines")
  const pushM1ToBack = 1
  const pushM2ToBack = -1
  machines.forEach((m: MachineEntry) => {
    m.adresseKurz = m.adresse && m.adresse.ort && m.adresse.strasse
      ? `${m.adresse.ort}, ${m.adresse.strasse}`
      : undefined
  })
  // console.log('res: ', items)
  const now = new Date()
  const longAgo = now.getTime() - 7 * 24 * 3600 * 1000
  // console.log(`longAgo = ${new Date(longAgo).toISOString()}`)
  machines.sort((m1: MachineEntry, m2: MachineEntry) => {
    // Sort decommissioned machines to end of list
    if (m1.machine_status !== m2.machine_status) {
      if (m1.machine_status === 'ausgebaut') {
        return pushM1ToBack
      }
      if (m2.machine_status === 'ausgebaut') {
        return pushM2ToBack
      }
      if (m1.machine_status === 'Dataplane deaktiviert') {
        return pushM1ToBack
      }
      if (m2.machine_status === 'Dataplane deaktiviert') {
        return pushM2ToBack
      }
    }

    const m1IsAnyConnected = m1.presence.isConnected || m1.presence.isAjaConnected
    const m2IsAnyConnected = m2.presence.isConnected || m2.presence.isAjaConnected
    const m1RecentlyConnected = m1IsAnyConnected
      || (m1.presence.disconnected?.timestamp && m1.presence.disconnected.timestamp > longAgo)
      || (m1.presence['aja-disconnected']?.timestamp && m1.presence['aja-disconnected'].timestamp > longAgo)
    const m2RecentlyConnected = m2IsAnyConnected
      || (m2.presence.disconnected?.timestamp && m2.presence.disconnected.timestamp > longAgo)
      || (m2.presence['aja-disconnected']?.timestamp && m2.presence['aja-disconnected'].timestamp > longAgo)
    if (m1RecentlyConnected && !m2RecentlyConnected) {
      return pushM2ToBack
    }
    if (!m1RecentlyConnected && m2RecentlyConnected) {
      return pushM1ToBack
    }

    // Sort by address
    if (m1.adresseKurz && !m2.adresseKurz) {
      return pushM2ToBack
    }
    if (!m1.adresseKurz && m2.adresseKurz) {
      return pushM1ToBack
    }
    if (m1.adresseKurz && m2.adresseKurz) {
      if (m1.adresseKurz < m2.adresseKurz) {
        return pushM2ToBack
      }
      if (m1.adresseKurz > m2.adresseKurz) {
        return pushM1ToBack
      }
    }

    // Sort by ext_sn
    return m1.ext_sn < m2.ext_sn
      ? pushM2ToBack
      : pushM1ToBack
  })
  return machines
}

export const filterMachines = (machines: MachineEntry[], filter: string | undefined) => {
  if (!filter || !machines) {
    return machines
  }
  return machines
    .filter((machine) => {
      const haystack = [
        machine.ext_sn,
        machine.adresse.strasse,
        machine.adresse.ort,
        machine.adresse.bezeichnung
      ].join('#').toLowerCase()
      return haystack.includes(filter)
    })
}
