import { Button, Flex, Image, useAuthenticator, useTheme } from "@aws-amplify/ui-react"
import './AuthenticatorStyles.css'

export const AuthenticatorHeader = () => {
  const { tokens } = useTheme()
  return (
    <div className="bg-white">
      <Flex justifyContent="start" alignItems="center">
        <Image
          alt="Strautmann Umwelttechnik"
          src="/logo.png"
          paddingInlineEnd={tokens.space.medium}
        />
        <Image
          alt="Strautmann PSI"
          src="/psi-logo.png"
          padding={tokens.space.medium}
          height={90}
        />
      </Flex>
    </div>
  )
}

export const AuthenticatorFooter = () => {
  return (
    <div className='fixed top-0 right-0 h-full'>
      <img src="/images/Strautmann_Autoloadbaler_niedrigbau_mit_Wagen2_und_Ballen_2024.jpg" className='h-full' style={{
        maxWidth: '50vw'
      }} />
    </div>
  )
}

export const AuthenticatorSignInHeader = () => {
  const { toSignUp } = useAuthenticator()
  return (
    <>
      <div className="call-to-signIn">
        Melden Sie sich jetzt mit Ihrem PSI Account an.
      </div>
      <div>
        Noch keinen Account?
        <Button
          fontWeight="normal"
          onClick={toSignUp}
          size="small"
          variation="link">Jetzt Account erstellen</Button>
      </div>
    </>
  )
}

export const AuthenticatorSignUpHeader = () => {
  return (
    <>
      <div className="call-to-signIn">
        Account erstellen.
      </div>
      <div>
        Geben Sie hier Ihre E-Mail-Adresse ein und vergeben Sie ein Passwort.
        Sie erhalten im Anschluss einen Link zur Aktivierung Ihres Accounts.
      </div>
    </>
  )
}

export const AuthenticatorSignUpFooter = () => {
  const { toSignIn } = useAuthenticator()
  return (
    <div>
      Sie haben bereits einen Account?
      <Button
        fontWeight="normal"
        onClick={toSignIn}
        size="small"
        variation="link">Zurück zur Anmeldung</Button>
    </div>
  )
}
