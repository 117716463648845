/**
 * @todo
 * Everything in this file can be moved to psi-lib
 * Also, DateTimeUtils.ts, types/*
 */

import { MachineEntry } from 'psi-lib'

export const getMachineFamily = (machine: MachineEntry) => {
  const deviceType = machine.device_type.toLocaleLowerCase()
  let machineFamily = 'AutoLoadBaler'
  if (deviceType.includes('jlb')) {
    machineFamily = 'JumboLoadBaler'
  }
  if (deviceType.includes('gpio')
    || deviceType.includes('ready')) {
    machineFamily = 'AutoLoadBaler Ready'
  }
  return machineFamily
}

/** Converts a duration in seconds into a human-readable (German) string */
export const secondsToString_old = (seconds: number) => {
  const numyears = Math.floor(seconds / 31536000)
  const numdays = Math.floor((seconds % 31536000) / 86400)
  const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600)
  const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
  const numseconds = Math.floor((((seconds % 31536000) % 86400) % 3600) % 60)
  const yearsStr = numyears ? numyears + " Jahre" : ''
  const daysStr = numdays ? numdays + " Tage" : ''
  const hoursStr = numhours < 10 ? '0' + numhours : numhours
  const minsStr = numminutes < 10 ? '0' + numminutes : numminutes
  const secsStr = numseconds < 10 ? '0' + numseconds : numseconds
  const hmsStr = `${hoursStr}:${minsStr}:${secsStr} Stunden`
  return [yearsStr, daysStr, hmsStr].join(' ')
}
