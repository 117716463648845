import { BaleReportDto, secondsToString, toLocalStringDe } from "psi-lib"

import ConnectivityIndicator from "./ConnectivityIndicator"

import './BaleStatsTable.css'

interface BaleStatsTableProps {
  baleStats: BaleReportDto
}
export function BaleStatsTable({ baleStats }: BaleStatsTableProps) {
  const beginn: Date = new Date(baleStats.start)
  // const ende: Date = new Date(data.SK.split('#')[1])
  const ende: Date = new Date(baleStats.end)
  const dauer = secondsToString((+ende - +beginn) / 1000)
  const ejection_type = baleStats.ejection_type.startsWith('before_bf')
    ? 'before_bf'
    : baleStats.ejection_type

  return <>
    <table className="row-headers bale-stats-table">
      <tbody>
        <tr>
          <th scope='row'>Beginn:</th>
          <td>{toLocalStringDe(beginn.toISOString())}</td>
        </tr>
        <tr>
          <th scope='row'>Ende:</th>
          <td>{toLocalStringDe(ende.toISOString())}</td>
        </tr>
        <tr>
          <th scope='row'>Dauer:</th>
          <td>{dauer}</td>
        </tr>
        <tr>
          <th scope='row'>Anz. Pressvorgänge gesamt:</th>
          <td>{baleStats.anzahl_pressvorgaenge}</td>
        </tr>
        <tr>
          <th scope='row'>Pressvorgangsdauer (gesamt):</th>
          <td>{secondsToString(baleStats.pressvorgangsdauer)}</td>
          <td>⌀ {0 === baleStats.anzahl_pressvorgaenge
            ? 0
            : secondsToString(baleStats.pressvorgangsdauer / baleStats.anzahl_pressvorgaenge)}</td>
        </tr>
        <tr>
          <th scope='row'>Anz. Hand-Pressvorgänge:</th>
          <td>{baleStats.anzahl_hand_pressvorgaenge}</td>
        </tr>
        <tr>
          <th scope='row'>Anz. Hand-Befüllungen:</th>
          <td>{baleStats.anzahl_hand_befuellungen}</td>
        </tr>
        <tr>
          <th scope='row'>Dauer Hand-Befüllungen:</th>
          <td>{secondsToString(baleStats.hand_entleerungsdauer)}</td>
          <td>⌀ {0 === baleStats.anzahl_hand_befuellungen
            ? 0
            : secondsToString(baleStats.hand_entleerungsdauer / baleStats.anzahl_hand_befuellungen)}</td>
        </tr>
        <tr>
          <th scope='row'>Anz. Wagen-Pressvorgänge:</th>
          <td>{baleStats.anzahl_wagen_pressvorgaenge}</td>
        </tr>
        <tr>
          <th scope='row'>Entleerungszeit (Wagen):</th>
          <td>{secondsToString(baleStats.wagen_entleerungsdauer)}</td>
          <td>⌀ {0 === baleStats.anzahl_wagen_pressvorgaenge
            ? 0
            : secondsToString(baleStats.wagen_entleerungsdauer / baleStats.anzahl_wagen_pressvorgaenge)}</td>
        </tr>
        <tr>
          <th scope='row'>Presszeit (Wagen):</th>
          <td>{secondsToString(baleStats.wagen_pressvorgangsdauer)}</td>
          <td>⌀ {0 === baleStats.anzahl_wagen_pressvorgaenge
            ? 0
            : secondsToString(baleStats.wagen_pressvorgangsdauer / baleStats.anzahl_wagen_pressvorgaenge)}</td>
        </tr>

        <tr>
          <th scope='row'>Dauer bis zum Auswurf:</th>
          {ejection_type !== 'before_bf' && (
            <td>{0 === baleStats.dauer_bis_auswurf
              ? "konnte nicht ermittelt werden"
              : secondsToString(baleStats.dauer_bis_auswurf)}</td>
          )}
          {ejection_type === 'before_bf' && (
            <td scope='row'>
              <span style={{ fontSize: '11px' }}><ConnectivityIndicator isConnected={false} name={'Vorzeitiger Auswurf'} isDisabled={false} /></span>
              {' '}
              Vorzeitiger Auswurf
            </td>
          )}
        </tr>
        <tr>
          <th scope='row'>Abbinde-Dauer:</th>
          <td>{0 === baleStats.abbinde_dauer
            ? "konnte nicht ermittelt werden"
            : secondsToString(baleStats.abbinde_dauer)}</td>
        </tr>
        <tr>
          <th scope='row'>Reinigungs-Dauer:</th>
          <td>{0 === baleStats.reinigungs_dauer
            ? "konnte nicht ermittelt werden"
            : secondsToString(baleStats.reinigungs_dauer)}</td>
        </tr>
      </tbody>
    </table>
  </>
}
