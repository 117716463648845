import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import App from './App'
import Homepage from './routes/home-page'
import { MachinePageWrapper } from './components/MachinePageWrapper'
import Maschinen from './routes/maschinen'
import Maschine from './routes/maschine'
import AlleStoerungen from './routes/stoerungen'
import DailyReport from './routes/daily-report'
import Reports from './routes/reports'
import BaleStats from './routes/bale-report'
import BaleReports from './routes/bale-reports'
import * as serviceWorker from './serviceWorker'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Homepage />
      },
      {
        path: "maschinen",
        children: [
          {
            index: true,
            element: <Maschinen />
          },
          {
            /** @todo: rename parameter to ext_sn */
            path: ":seriennummer",
            element: <MachinePageWrapper />,
            children: [
              {
                index: true,
                element: <Maschine />
              },
              {
                path: "stoerungen",
                children: [
                  {
                    index: true,
                    element: <AlleStoerungen />
                  }
                ]
              },
              {
                path: "reports",
                children: [
                  {
                    index: true,
                    element: <Reports />
                  },
                  {
                    path: "daily",
                    children: [
                      {
                        index: true,
                        element: <DailyReport />
                      },
                      {
                        path: ":date",
                        element: <DailyReport />
                      }
                    ]
                  },
                  {
                    path: "bales",
                    children: [
                      {
                        index: true,
                        element: <BaleReports />
                      },
                      {
                        path: ":baleStatsId",
                        element: <BaleStats />
                      }
                    ]
                  }
                ]
              },
              // Have one of those catch-alls here, inside the maschinen/ path
              {
                path: "*",
                element: (
                  <main style={{ padding: '1rem' }}>
                    <p>Hier gibt's nichts zu sehen!</p>
                  </main>
                )
              }
            ]
          }
        ]
      },
      {
        path: "*",
        element: (
          <main style={{ padding: '1rem' }}>
            <p>Hier gibt's nichts zu sehen!</p>
          </main>
        )
      }
    ]
  }
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
