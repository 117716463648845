/**
 * Properties of a BaleReport object.
 */

import { InferType, number, object, string } from "yup"

export const BaleReportSchema = object({
  ballennummer: number().integer().positive().required(),
  start: string().required(),
  end: string().required(),
  ejection_type: string().required(),
  anzahl_pressvorgaenge: number().integer().min(0).required(),
  pressvorgangsdauer: number().min(0).required(),
  anzahl_presshuebe: number().integer().min(0),
  anzahl_hand_pressvorgaenge: number().integer().min(0).required(),
  anzahl_hand_befuellungen: number().integer().min(0).required(),
  hand_entleerungsdauer: number().min(0).required(),
  anzahl_wagen_pressvorgaenge: number().integer().min(0).required(),
  wagen_entleerungsdauer: number().min(0).required(),
  wagen_pressvorgangsdauer: number().min(0).required(),
  dauer_bis_auswurf: number().min(0).required(),
  abbinde_dauer: number().min(0).required(),
  reinigungs_dauer: number().min(0).required(),
})

export type BaleReportDto = InferType<typeof BaleReportSchema>

/** @deprecated Use `BaleReportDto` instead. */
export type BaleStatsDto = BaleReportDto

type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};
type bsDto = Prettify<BaleReportDto>
