import { Flex, Grid, useTheme, View } from '@aws-amplify/ui-react'
import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { FaDownload, FaRegCalendarDays } from 'react-icons/fa6'
import { useParams } from 'react-router-dom'

import { MachineEntry, secondsToString } from 'psi-lib'

import { getMachine } from '../api/MachinesApi'
import { ButtonWithIcon } from '../components/ButtonWithIcon'
import Fehlerliste from '../components/maschinen-details/Fehlerliste'
import { ModemDetails } from '../components/maschinen-details/ModemDetails'
import { SonstigeMaschinenDetails } from '../components/maschinen-details/SonstigeMaschinenDetails'
import StandortDetails from '../components/maschinen-details/StandortDetails'
import StatusDetails from '../components/maschinen-details/StatusDetails'
import VerbindungsDetails from '../components/maschinen-details/VerbindungsDetails'
import { RoundedCard } from '../components/RoundedCard'

import './maschine.css'

export default function Maschine() {
  const [machine, setMachine] = React.useState<MachineEntry | null>(null)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const { tokens } = useTheme()
  const params = useParams()
  const ext_sn = params.seriennummer

  React.useEffect(() => {
    if (!ext_sn) {
      setError("Ohne Maschinennummer können keine Maschinendaten geladen werden.")
      setLoading(false)
      return
    }
    getMachine(ext_sn)
      .then(m => {
        // console.log(m)
        setMachine(m)
        setError(undefined)
        setLoading(false)
      })
      .catch(err => {
        console.log('err:')
        console.log(err)
        setMachine(null)
        setError(err)
        setLoading(false)
      })
  }, [ext_sn])

  if (loading) {
    return <>Lade Maschinendaten, bitte warten...</>
  }
  if (error) {
    return <>Fehler: {error}</>
  }

  const loadedMachine = machine!
  const deviceState = loadedMachine.deviceState
  // console.debug(maschine)
  return (
    <>
      <Flex className='!mb-4' alignItems='center' justifyContent='space-between'>
        <div className='section-heading !mb-0'>Übersicht</div>
        <div className='spacer ml-auto' />
        {/* <ButtonWithIcon
          title="Download Excel"
          icon={<FaDownload />} /> */}
        <ButtonWithIcon
          title="13.07. - 12.08.2024"
          icon={<FaRegCalendarDays />} />
        <ButtonWithIcon
          title="Letzte Woche"
          icon={<FaRegCalendarDays />} />
      </Flex>

      <Grid gap={tokens.space.small}
        templateColumns="repeat(4, 1fr)"
      >
        <View columnSpan={2} rowSpan={2}>
          <RoundedCard className='!p-0'>
            <Flex gap='0px'>
              <StandortDetails className='p-6' maschine={loadedMachine} />
              <div className='w-1/2 h-80'>
                {machine?.adresse.gps
                  ? <div className='!bg-emerald-300 h-full rounded-tr-lg rounded-br-lg'>Hier wird noch eine Karte eingebunden...</div>
                  : <div className='p-6 !bg-emerald-300 h-full rounded-tr-lg rounded-br-lg'>GPS-Koordinaten sind nicht gepflegt, deshalb kann keine Karte angezeigt werden.</div>}
              </div>
            </Flex>
          </RoundedCard>
        </View>

        <View>
          <RoundedCard className="h-full">
            <div className='card-heading'>Anzahl Ballen</div>
            {deviceState && deviceState.anzahlBallen
              ? (
                <div className='key-number'>
                  {deviceState.anzahlBallen}
                </div>
              )
              : ''}
          </RoundedCard>
        </View>
        <View>
          <RoundedCard className="h-full">
            <div className='card-heading'>Betriebsstunden</div>
            {deviceState && deviceState.betriebsStunden
              ? (
                <div className='key-number'>
                  {secondsToString(deviceState.betriebsStunden)}
                </div>
              )
              : ''}
          </RoundedCard>
        </View>
        <View>
          <RoundedCard className="h-full">
            <div className='card-heading'>Letzter Ballenbericht</div>
            <div className='key-number'>Was kommt hierhin?</div>
          </RoundedCard>
        </View>
        <View>
          <RoundedCard className="h-full">
            <div className='card-heading'>Letzter Tagesbericht</div>
            <div className='key-number'>Was kommt hierhin?</div>
          </RoundedCard>
        </View>

        <View columnSpan={2}>
          <RoundedCard className="h-full">
            <SonstigeMaschinenDetails maschine={loadedMachine} />
          </RoundedCard>
        </View>

        <View columnSpan={2}>
          <RoundedCard className="h-full">
            <ModemDetails machine={loadedMachine} />
          </RoundedCard>
        </View>

        <View columnSpan={2}>
          <RoundedCard>
            <ErrorBoundary fallback={(
              <div>
                <strong>Verbindung</strong><br />
                Vermutlich noch nie online gewesen.
              </div>
            )}>
              <VerbindungsDetails maschine={loadedMachine} />
            </ErrorBoundary>
          </RoundedCard>
        </View>
      </Grid>
      <Flex>
        {/* Kennzahlen */}
        <StatusDetails maschine={loadedMachine} />
      </Flex>
      <br />
      <div><a href='reports/'><button className='reports-link'>Berichte ansehen</button></a></div>
      <br />
      <br />
      <Fehlerliste maschine={loadedMachine} maxDisplayLength={10} />
      <br />
      {/* <RulesDetails maschine={maschine} /> */}
    </>
  )
}
