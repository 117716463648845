import { PropsWithChildren } from "react"
import './RoundedCard.css'

interface RoundedCardProps extends PropsWithChildren {
  className?: string
}
export const RoundedCard = ({ children, className }: RoundedCardProps) => {
  return (
    <div className={`rounded-card ${className || ''}`}>
      {children}
    </div>
  )
}