import { useParams } from 'react-router-dom';
// import './reports.css'

import { BaleReportsList } from './bale-reports';
import { Breadcrumbs } from '../LinkUtils';

export default function Reports() {
  const params = useParams<{ seriennummer: string | undefined }>()
  const ext_sn = params.seriennummer

  if (!ext_sn) {
    return <div>Fehler beim Laden der Berichte. Keine Maschinennummer angegeben.</div>
  }

  return (
    <div style={{ display: 'flex' }}>
      <main style={{ padding: '0rem 1rem' }}>
        <h2>Reports für {ext_sn}</h2>
        <Breadcrumbs seriennummer={ext_sn} />

        <h3>Ballenberichte</h3>
        <BaleReportsList ext_sn={ext_sn} />
      </main>
    </div>
  );
}
