import { MachineEntry } from 'psi-lib'
import { getLinkToMaschineStoerungen } from '../../LinkUtils'

interface ErrorEvent {
  dt: string
  eventName: string
}

interface FehlerlisteProps {
  maschine: MachineEntry
  maxDisplayLength: number
  [key: string]: any
}
export default function Fehlerliste({ maschine, maxDisplayLength = 10 }: FehlerlisteProps) {
  // case of ALB ready
  if (maschine.ext_sn && maschine.ext_sn.startsWith('411.')) {
    return (
      <div>
        <strong>Störungen</strong><br />
        <div>Für ALB ready Maschinen können keine Störungen angezeigt werden.</div>
      </div>
    )
  }

  // case of empty error list
  const errorList = maschine.errors
  if (!errorList || errorList.length === 0) {
    return (
      <div>
        <strong>Störungsfrei</strong><br />
        <div>Für diese Maschine liegen keine Störungen vor.</div>
      </div>
    )
  }

  const errorListSorted = errorList
    .sort((e1: ErrorEvent, e2: ErrorEvent) => {
      // neg. if first is less
      // 0 if equal
      // pos. otherwise
      return e1.dt < e2.dt ? 1 : -1
    })
  const errorListClipped = maxDisplayLength
    ? errorListSorted.slice(0, maxDisplayLength)
    : errorListSorted
  const errorListMapped = errorListClipped.map((errorEvent: any) => (
    <Fehlereintrag key={errorEvent.dt} fehler={errorEvent} />
  ))
  const isClipped = errorList.length > errorListMapped.length

  return (
    <div>
      {errorList.length > errorListMapped.length
        ? <>
          letzte {maxDisplayLength} Einträge &ndash; <a href={getLinkToMaschineStoerungen(maschine)}>alle zeigen</a>
        </>
        : <>{errorList.length} Einträge</>}<br />
      <table className="stoerungen w-1/2">
        <thead>
          <tr>
            <th>Datum</th>
            <th></th>
            <th>Uhrzeit</th>
            <th></th>
            <th>Störung</th>
          </tr>
        </thead>
        <tbody>
          {errorListMapped}
        </tbody>
      </table>
    </div>
  )
}

interface FehlereintragProps {
  fehler: ErrorEvent
  [key: string]: any
}
function Fehlereintrag({ fehler }: FehlereintragProps) {
  const dateOfError = new Date(fehler.dt)
  const [date, time] = dateOfError.toISOString().split('.')[0].split('T')
  const ageInSeconds = Math.floor((new Date().getTime() - dateOfError.getTime()) / 1000)
  const ageInDays = ageInSeconds / 86400
  const rowClassName = ageInDays < 14 ? 'stoerung-recent' : 'stoerung-old'
  return (
    <tr className={rowClassName}>
      <td>{date}</td>
      <td>&nbsp;</td>
      <td>{time}</td>
      <td>&nbsp;</td>
      <td>{fehler.eventName}</td>
    </tr>
  )
}
