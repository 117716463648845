import { Flex } from '@aws-amplify/ui-react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, TimeScale, Title, Tooltip, Legend } from "chart.js"
import 'chartjs-adapter-luxon'
import { useEffect, useState } from 'react'
import { Bar } from "react-chartjs-2"
import { ErrorBoundary } from 'react-error-boundary'
import { FaDownload, FaRegCalendarDays } from 'react-icons/fa6'
import { useNavigate, useParams } from 'react-router-dom'

import { DailyReportResponseDto, DailyReportRowDto, dateOnly, roundHalfHourDown, roundHalfHourUp, splitPascalCase, toLocalDateStringDe, toLocalStringDe } from 'psi-lib'

import { getDailyReport, getMachine, getRecentDailyReports } from '../api/MachinesApi'
import { ButtonWithIcon, SelectButtonWithIcon } from '../components/ButtonWithIcon'
import { RoundedCard } from '../components/RoundedCard'
import { getLinkToMaschineDailyReport } from '../LinkUtils'

import './reports.css'

ChartJS.register(
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
)

const utcDateColumn = 'Datum'
const localDateColumn = 'Datum (Local)'
const utcTimeColumn = 'Uhrzeit (UTC)'
const localTimeColumn = 'Uhrzeit (Local)'

interface DailyReportChartJsProps {
  data: DailyReportResponseDto
}
function DailyReportChartJs({ data }: DailyReportChartJsProps) {
  const rows = data.rows

  const pzColumn = 'Zykluszeit'
  const ezColumn = 'Entleerungszeit'
  const bfColumn = 'Entleerungszeit'

  const xLabels = rows.map((row) => {
    const dtUtc = row[utcDateColumn] + 'T' + row[utcTimeColumn] + 'Z'
    return dtUtc
    // const tm = row[localTimeColumn]
    // return tm
  })
  // xLabels.unshift("x")
  console.log('xLabels', xLabels)

  const isHandRow = (row: DailyReportRowDto) => {
    return Number(row[ezColumn]) === 0
  }
  const isBfRow = (row: DailyReportRowDto) => {
    return row.Fehlermeldung === 'DBG:Ballen Fertig'
      || row.Fehlermeldung === 'Ballen fertig'
      || row.Fehlermeldung === 'Ballen ausgeworfen'
  }
  const isRestartRow = (row: DailyReportRowDto) => {
    return row.Fehlermeldung.includes('DBG:Restart detected')
  }

  const pzSeries = rows.map((row) => {
    if (isBfRow(row) || isRestartRow(row)
      || isHandRow(row)) {
      return 0
    }
    return Number(row[pzColumn])
  })
  // pzSeries.unshift(pzColumn)
  console.log('pzSeries', pzSeries)

  const ezSeries = rows.map(row => {
    if (isBfRow(row) || isRestartRow(row)
      || isHandRow(row)) {
      return 0
    }
    return Number(row[ezColumn])
  })
  // ezSeries.unshift(ezColumn)
  console.log('ezSeries', ezSeries)

  const handSeries = rows.map(row => {
    if (isBfRow(row) || isRestartRow(row)
      || !isHandRow(row)) {
      return 0
    }
    return Number(row[pzColumn])
  })
  console.log('handSeries', handSeries)

  const bfSeries = rows.map(row => {
    if (!isBfRow(row)) {
      return 0
    }
    return 200
  })
  console.log('bfSeries', bfSeries)

  // const BORDER_COLORS = [
  //   'rgb(54, 162, 235)', // blue
  //   'rgb(255, 99, 132)', // red
  //   'rgb(255, 159, 64)', // orange
  //   'rgb(255, 205, 86)', // yellow
  //   'rgb(75, 192, 192)', // green
  //   'rgb(153, 102, 255)', // purple
  //   'rgb(201, 203, 207)' // grey
  // ];

  // // Border colors with 50% transparency
  // const BACKGROUND_COLORS = /* #__PURE__ */ BORDER_COLORS.map(color => color.replace('rgb(', 'rgba(').replace(')', ', 0.5)'));

  const barThickness = 6
  const barPercentage = 8
  const cdata = {
    labels: xLabels,
    datasets: [
      {
        label: "Entleerungszeit Wagenbefüllungen",
        data: ezSeries,
        borderWidth: 1,
        barThickness: barThickness,
        // barPercentage: barPercentage,
        borderColor: '#164194', // SOLL: DUNKELBLAU
        backgroundColor: '#164194' // SOLL: DUNKELBLAU, evtl. alpha = 0.5
      },
      {
        label: "Presszeit Wagenbefüllungen",
        data: pzSeries,
        borderWidth: 1,
        barThickness: barThickness,
        // barPercentage: barPercentage,
        borderColor: 'rgb(28, 155, 216)', // SOLL: HELLBLAU
        backgroundColor: 'rgb(28, 155, 216)', // SOLL: HELLBLAU, evtl. alpha = 0.5
      },
      {
        label: "Presszeit Handbefüllungen",
        data: handSeries,
        borderWidth: 1,
        barThickness: barThickness,
        // barPercentage: barPercentage,
        borderColor: '#FFCC00', // SOLL: GELB
        backgroundColor: '#FFCC00', // SOLL: GELB, evtl. alpha = 0.5
        order: -2
      },
      {
        label: "Ballen Fertig",
        data: bfSeries,
        borderWidth: 1,
        barThickness: barThickness,
        // barPercentage: barPercentage,
        borderColor: '#63FF84',
        backgroundColor: '#B1FFC1',
        order: -1
      },
    ]
  }

  /** find the first and last entries which have a non-zero bar */
  let firstBar = -1
  let lastBar = 0
  for (let index = 0; index < xLabels.length; index++) {
    if (ezSeries[index] > 0
      || pzSeries[index] > 0
      || handSeries[index] > 0
      || bfSeries[index] > 0) {
      if (-1 === firstBar) {
        firstBar = index
      }
      lastBar = index
    }
  }
  const minDate = new Date(xLabels[firstBar])
  const suggestedMin = roundHalfHourDown(minDate).toISOString()

  const maxDate = new Date(xLabels[lastBar])
  const suggestedMax = roundHalfHourUp(maxDate).toISOString()

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: `Pressvorgänge am ${data.dateOfReport}`,
      }
    },
    scales: {
      x: {
        bounds: 'data' as const,
        // // suggestedMin: suggestedMin,
        min: suggestedMin,
        // // suggestedMax: suggestedMax,
        max: suggestedMax,
        type: 'time' as const,
        ticks: {
          source: 'auto' as const,
          stepSize: 30,
          /** @note without a callback, the chart collapses every now and then */
          callback: (value: any) => {
            const dt = new Date(value)
            return ('0' + dt.getHours()).slice(-2)
              + ':'
              + ('0' + dt.getMinutes()).slice(-2)
          }
        },
        display: true,
        stacked: true
      }
    }
  }

  return <div><Bar className='daily-chart' data={cdata} options={options} /></div>
}

function DailyReportTable({ data }: DailyReportChartJsProps) {
  const rows = data.rows
  const headings = Object.keys(rows[0]) as [keyof DailyReportRowDto]
  // Get index of date and time columns
  const dateIndex = headings.indexOf(utcDateColumn)
  const timeIndex = headings.indexOf(utcTimeColumn)
  // Remove local date and time columns from headings
  headings.splice(headings.indexOf(localDateColumn), 1)
  headings.splice(headings.indexOf(localTimeColumn), 1)
  // Replace utc date and time columns with local ones in headings
  headings.splice(dateIndex, 1, localDateColumn)
  headings.splice(timeIndex, 1, localTimeColumn)

  // Remove Date, WagenNumemr
  headings.splice(headings.indexOf(localDateColumn), 1)
  headings.splice(headings.indexOf('WagenNummer'), 1)

  return <table className='daily-report' lang='de'>
    <thead>
      <tr key='th-row'>{headings.map(h => (
        <th key={h}>
          {splitPascalCase(h.replace(' (Local)', ''))
            .replace('Handbefuellungen', 'Handbefüllungen')
            .replace('Huebe', 'Hübe')
            .replace('Tuer', 'Tür')
            .replace('Max ', 'Max. ')
            .replace('Anzahl ', 'Anz. ')
            .replace('Nummer', 'Nr.')
            }
        </th>
      ))}</tr>
    </thead>
    <tbody>
      {rows.map((row, index) => {
        return <tr key={index}>
          {headings.map(h => <td key={h}>{row[h]}</td>)}
        </tr>
      })}
    </tbody>
  </table>
}

export default function DailyReport() {
  const navigate = useNavigate()
  const params = useParams<{
    seriennummer: string | undefined
    date: string | undefined
  }>()
  const ext_sn = params.seriennummer
  const pDateOfReport = params.date

  const [machine, setMachine] = useState<any | undefined>(undefined)
  const [reportData, setReportData] = useState<DailyReportResponseDto | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [dateOfReport, setDateOfReport] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (!ext_sn) {
      setError("Ohne Maschinennummer können keine Maschinendaten geladen werden.")
      setLoading(false)
      return
    }

    const fetchDateOfLatestReport = async (ext_sn: string): Promise<string> => {
      const reports = await getRecentDailyReports(ext_sn)
      if (!reports || Array.isArray(reports) || !reports.latest) {
        throw new Error(`Keine Daten für ${ext_sn} gefunden.`)
      }
      console.log('reports', reports)
      return reports.latest[0] as string
    }
    const fetchReportData = async (ext_sn: string, dateOfReport?: string) => {
      if (!dateOfReport) {
        console.log("No dateOfReport, getting latest date", dateOfReport)
        dateOfReport = await fetchDateOfLatestReport(ext_sn)
        console.log("dateOfReport set to", dateOfReport)
      }
      setDateOfReport(new Date(dateOfReport).getTime())
      const report = await getDailyReport(ext_sn, dateOfReport)
      if (!report || !report.rows) {
        throw new Error(`Keine Daten für ${ext_sn} und ${dateOfReport} gefunden.`)
      }
      return report
    }
    const fetchAllData = async (ext_sn: string, dateOfReport?: string) => {
      try {
        const [myMaschineData, myReportData] = await Promise.all([
          getMachine(ext_sn),
          fetchReportData(ext_sn, dateOfReport)
        ])
        console.log(myMaschineData)
        setMachine(myMaschineData)
        setReportData(myReportData)
      } catch (err) {
        console.error('Error:', err)
        setMachine(undefined)
        setReportData(undefined)
        if (err instanceof Error) {
          setError(err.message)
        } else {
          setError((err as any).toString())
        }
      } finally {
        setLoading(false)
      }
    }
    fetchAllData(ext_sn, pDateOfReport)
  }, [ext_sn, pDateOfReport])

  if (loading) {
    return <>Lade Maschinendaten, bitte warten...</>
  }
  if (error) {
    /** @todo: params.date may be undefined, for a latest report */
    const _dayOfReportIsSunday = dateOfReport
      ? 0 === new Date(dateOfReport).getDay()
      : false
    return (
      <>
        <div>Fehler: {error}</div>
        {_dayOfReportIsSunday && (
          <div>
            Möglicherweise liegt das daran, dass der {new Date(dateOfReport!).toLocaleDateString(undefined, { dateStyle: 'short' })} ein Sonntag war.
          </div>
        )}
      </>
    )
  }

  if (reportData) {
    const rows = reportData.rows

    rows.forEach(row => {
      const dtUtc = row[utcDateColumn] + 'T' + row[utcTimeColumn] + 'Z'
      const dtLocalSplit = toLocalStringDe(dtUtc).split(',')
      row[localDateColumn] = dtLocalSplit[0].trim()
      row[localTimeColumn] = dtLocalSplit[1].trim()
    })
  }

  const loadedMachine = machine!
  const loadedReport = reportData!

  let content = (
    <>
      <ErrorBoundary fallback={<div>Kann den Graph nicht darstellen.</div>}>
        <DailyReportChartJs data={loadedReport} />
      </ErrorBoundary>
      <br />
      <DailyReportTable data={loadedReport} />
    </>
  )

  if (dateOfReport) {
    const oneDay = 24 * 3600 * 1000
    const dayBefore = new Date(dateOfReport - oneDay).toISOString().slice(0, 10)
    const dayAfter = new Date(dateOfReport + oneDay).toISOString().slice(0, 10)
    const dayLinks = (
      <Flex style={{ width: "100%" }}
        direction='row'
        justifyContent="space-between">
        <a href={getLinkToMaschineDailyReport(loadedMachine, dayBefore)}>« vorheriger Tag</a>
        <a href={getLinkToMaschineDailyReport(loadedMachine, dayAfter)}>nächster Tag »</a>
      </Flex>
    )
    content = <>
      {dayLinks}
      {content}
      {dayLinks}
    </>
  }

  if (!ext_sn) {
    return <div>Fehler beim Laden des Tagesberichts. Keine Maschinennummer angegeben.</div>
  }

  const dateOptions = []
  const nowTs = new Date().getTime()
  for (let i = 1; i < 8; i++) {
    const dayTs = nowTs - i * 24 * 3600 * 1000
    dateOptions.push(dateOnly(new Date(dayTs).toISOString()))
  }
  const dateOptionsMapped = dateOptions.map(dt => {
    return {
      text: toLocalDateStringDe(dt),
      value: dt
    }
  })
  return (
    <RoundedCard>
      <Flex className='!mb-6' alignItems='center' justifyContent='space-between'>
        <div className='section-heading !mb-0'>Bericht {new Date(dateOfReport!).toLocaleDateString(undefined, { dateStyle: 'long' })}</div>
        <div className='spacer ml-auto' />
        <ButtonWithIcon
          title="Download Excel"
          icon={<FaDownload />} />
        {/* <ButtonWithIcon
          title="13.07.2024"
          icon={<FaRegCalendarDays />} /> */}
        <SelectButtonWithIcon
          options={dateOptionsMapped}
          selected={dateOnly(new Date(dateOfReport!).toISOString())}
          onChange={newDate => navigate(getLinkToMaschineDailyReport(loadedMachine, newDate))}
          icon={<FaRegCalendarDays />} />
      </Flex>

      {content}
    </RoundedCard>
  )
}
