/**
 * Converts a duration in seconds into a human-readable (German) string
 */
export const secondsToString = (seconds: number) => {
  const numyears = Math.floor(seconds / 31536000)
  const numdays = Math.floor((seconds % 31536000) / 86400)
  const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600)
  const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
  const numseconds = Math.floor((((seconds % 31536000) % 86400) % 3600) % 60)
  const yearsStr = numyears ? numyears + " Jahre" : ''
  const daysStr = numdays ? numdays + " Tage" : ''
  const hoursStr = numhours < 10 ? '0' + numhours : numhours
  const minsStr = numminutes < 10 ? '0' + numminutes : numminutes
  const secsStr = numseconds < 10 ? '0' + numseconds : numseconds
  let timeStr
  if (numyears > 0
    || numdays > 0
    || numhours > 0) {
    timeStr = `${hoursStr}:${minsStr}:${secsStr} Stunden`
  } else {
    if (numminutes > 0) {
      timeStr = `${minsStr}:${secsStr} Minuten`
    } else {
      if (1 === numseconds) {
        timeStr = `${numseconds} Sekunde`
      } else {
        timeStr = `${numseconds} Sekunden`
      }
    }
  }
  return [yearsStr, daysStr, timeStr].join(' ')
}

/**
 * Converts a UTC ISO datetime string like "2025-01-01T13:37:00.000Z" to local German format, e.g. "01.01.2025, 14:37:00".
 */
export const toLocalStringDe = (datestr_utc: string) => {
  return new Date(datestr_utc).toLocaleString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
}

/**
 * Converts an ISO date string like "2025-01-01" to local German format, e.g. "01.01.2025".
 */
export const toLocalDateStringDe = (datestr_utc: string) => {
  return new Date(datestr_utc).toLocaleString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

/**
 * Splits an ISO datetime and returns only the date part.
 */
export const dateOnly = (dateTimeIso: string) => dateTimeIso.split('T')[0]

/**
 * Takes a date and rounds its value up to the next half hour.
 * @param date 
 * @returns 
 */
export const roundHalfHourDown = (date: Date): Date => {
  const minutes = date.getMinutes()
  const hours = date.getUTCHours()
  const roundedMinutes = Math.floor(minutes / 30) * 30
  const roundedHours = hours + Math.floor(minutes / 60)

  date.setUTCHours(roundedHours, roundedMinutes, 0, 0)
  return date
}

/**
 * Takes a date and rounds its value down to the next half hour.
 * @param date 
 * @returns 
 */
export const roundHalfHourUp = (date: Date): Date => {
  const minutes = date.getMinutes()
  const hours = date.getUTCHours()
  const roundedMinutes = Math.ceil(minutes / 30) * 30
  const roundedHours = hours + Math.floor(roundedMinutes / 60)

  date.setUTCHours(roundedHours, roundedMinutes % 60, 0, 0)
  return date
}
