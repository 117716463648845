import { isDev } from "../../config"
import { MachineEntry } from 'psi-lib'
import { getMachineFamily } from "../../UtilsForPsiLib"

interface SonstigeMaschinenDetailsProps {
  maschine: MachineEntry
}
export const SonstigeMaschinenDetails = ({ maschine }: SonstigeMaschinenDetailsProps) => {
  let machine_model = getMachineFamily(maschine)
  let sps_model = 'unbekannt'
  if (maschine.device_type) {
    if (isDev) {
      if ('modbus' === maschine.device_type) {
        machine_model += ' (Samos)'
      }
      if ('s7' === maschine.device_type) {
        machine_model += ' (Logo)'
      }
    }

    if ('gpio' === maschine.device_type) {
      sps_model = 'ALB ready'
    }
    if ('modbus' === maschine.device_type) {
      sps_model = 'Samos'
    }
    if ('s7' === maschine.device_type) {
      sps_model = 'Logo'
    }
  }

  return (
    <div>
      <div className='card-heading'>Sonstige Maschinendaten</div>
      <table className="row-headers w-full">
        <tbody>
          <tr>
            <th scope="row" className="w-1/2">
              Maschinentyp:
            </th>
            <td>
              {machine_model}
            </td>
          </tr>
          <tr>
            <th scope="row">
              Anzahl Sammelwagen:
            </th>
            <td>
              {maschine.anzahlSammelwagen ?? <i>Daten nicht gepflegt</i>}
            </td>
          </tr>
          <tr>
            <th scope="row">
              Inbetriebnahme Maschine:
            </th>
            <td>
              {maschine.inbetriebnahmeMaschine ?? <i>Daten nicht gepflegt</i>}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
