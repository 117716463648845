import { MinimalMachineEntry } from 'psi-lib'

export const getLinkToMachines = () => {
  return `/maschinen/`
}

export const getLinkToMaschine = (maschine: MinimalMachineEntry) => {
  // This function has maschine by design.
  return `/maschinen/${maschine.ext_sn}/`
}

export const getLinkToMaschineStoerungen = (maschine: MinimalMachineEntry) => `${getLinkToMaschine(maschine)}stoerungen/`
export const getLinkToMaschineReportsOverview = (maschine: MinimalMachineEntry) => `${getLinkToMaschine(maschine)}reports/`
export const getLinkToMaschineDailyReportsOverview = (maschine: MinimalMachineEntry) => `${getLinkToMaschine(maschine)}reports/daily/`
export const getLinkToMaschineDailyReport = (maschine: MinimalMachineEntry, dateOfReport: string) => `${getLinkToMaschineDailyReportsOverview(maschine)}${dateOfReport}/`
export const getLinkToMaschineBaleStatsOverview = (maschine: MinimalMachineEntry) => `${getLinkToMaschine(maschine)}reports/bales/`
export const getLinkToMaschineBaleStats = (maschine: MinimalMachineEntry, baleStatsId: any) => `${getLinkToMaschineBaleStatsOverview(maschine)}${baleStatsId}/`

export const getLinkToMachineFamilyImage = (machineFamily: string) => {
  return `/images/${machineFamily}.png`
}

interface BreadcrumbsProps {
  seriennummer: string
  level1?: string
  level2?: string
  level3?: string
  [key: string]: any
}
export const Breadcrumbs = ({ seriennummer, level1, level2, level3 }: BreadcrumbsProps) => {
  const maschineDummy: MinimalMachineEntry = {
    ext_sn: seriennummer
  }
  return (
    <p className='breadcrumbs'>
      Zurück zur{' '}
      <a href={getLinkToMaschine(maschineDummy)}>Maschine</a>
      {'reports' === level1 && (
        <>
          {' > '}
          <a href={getLinkToMaschineReportsOverview(maschineDummy)}>Berichte-Übersicht</a>
        </>
      )}
      {'daily-reports' === level2 && (
        <>
          {' > '}
          <a href={getLinkToMaschineDailyReportsOverview(maschineDummy)}>Übersicht Daily Reports</a>
        </>
      )}
      {'bale-stats' === level2 && (
        <>
          {' > '}
          <a href={getLinkToMaschineBaleStatsOverview(maschineDummy)}>Übersicht Ballenberichte</a>
        </>
      )}
      {level3 && (
        <>
          {' > '}
          {level3}
        </>
      )}
    </p>
  )
}
