import { Authenticator, Flex, Theme, ThemeProvider, translations, useAuthenticator, View } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import { I18n } from 'aws-amplify/utils'
import { Outlet } from 'react-router-dom'

import { configureApis } from './api/utils'
import { AuthenticatorFooter, AuthenticatorHeader, AuthenticatorSignInHeader, AuthenticatorSignUpFooter, AuthenticatorSignUpHeader } from './components/AuthenticatorStyles'
import MainMenu from './components/MainMenu'
import { SearchField2 } from './components/SearchField2'
import { isProd } from './config'
import amplifyconfig from './amplifyconfiguration.json'

import './tw-init.css'
import '@aws-amplify/ui-react/styles.css'
// Supports weights 200-800
import '@fontsource-variable/plus-jakarta-sans'
import './global.css'
import './App.css'

Amplify.configure(amplifyconfig)

configureApis()

I18n.putVocabularies(translations)

const psiTheme: Theme = {
  name: "psi-theme",
  primaryColor: 'blue',
  secondaryColor: 'yellow',
  tokens: {
    colors: {
      font: {
        primary: { value: '{colors.neutral.100.value}' }
      },
      blue: {
        10: { value: '#E3F1FC' }, // 50
        20: { value: '#BADBF7' }, // 100
        40: { value: '#90C5F2' }, // 200
        60: { value: '#479DEA' }, // 400
        80: { value: '#2880D8' }, // 600
        90: { value: '#1E5DB3' }, // 800
        100: { value: '#164194' }, // 900
      },
      yellow: {
        10: { value: '#FFF8E0' }, // 50
        20: { value: '#FFEDB0' }, // 100
        40: { value: '#FFE17C' }, // 200
        60: { value: '#FFCC00' }, // 400
        80: { value: '#FFB500' }, // 600
        90: { value: '#FF8F00' }, // 800
        100: { value: '#FF6D00' }, // 900
      },
      neutral: {
        10: { value: '#F2F7FF' }, // 50
        20: { value: '#E8EDFA' }, // 100
        40: { value: '#DBE0EE' }, // 200
        60: { value: '#A3A8B5' }, // 400
        80: { value: '#5B606B' }, // 600
        90: { value: '#2B2F39' }, // 800
        100: { value: '#0A0F19' }, // 900
      }
    },
    fonts: {
      default: {
        static: '"Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif',
        variable: '"Plus Jakarta Sans Variable", "PlusJakartaSansVariable", "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif',
      },
    }
  }
}

function AppAuthenticated() {
  const { authStatus, user } = useAuthenticator(context => [context.authStatus, context.user])
  // const context = useAuthenticator()
  // const { authStatus, user, signOut } = context
  console.log('authStatus', authStatus)
  console.log('user', user)
  // console.log('auth context', context)

  if (authStatus === "configuring") {
    return isProd
      ? <>Lade die App ...</>
      : <>Warte auf Authenticator (authStatus === "configuring") ...</>
  }

  if (authStatus === "unauthenticated") {
    const components = {
      Header: AuthenticatorHeader,
      Footer: AuthenticatorFooter,
      SignIn: {
        Header: AuthenticatorSignInHeader

      },
      SignUp: {
        Header: AuthenticatorSignUpHeader,
        Footer: AuthenticatorSignUpFooter,
      }
    }
    return <Authenticator
      components={components}
      variation='modal'
    />
  }

  return (
    <>
      <header className="App-header">
        <div className='logo-container'>
          <img className='psi-logo' src="/psi-logo.png" alt="Strautmann PSI" />
        </div>
        <div>
          <SearchField2 />
        </div>
      </header>

      <MainMenu />

      <div className='content-area'>
        <View className='main-container'>
          <Outlet />
        </View>

        <footer className='mb-8'>
          <div>
            <a href='https://www.strautmann-umwelt.com/' target='_blank'>
              <img src="/logo-transparent.png" className='h-10 absolute right-16' />
            </a>
          </div>
          <Flex className='!h-10' justifyContent='center' alignItems='center'>
            <nav>
              <a>Impressum</a>
              |
              <a>Nutzungsbedingungen</a>
              |
              <a>Datenschutzerklärung</a>
            </nav>
          </Flex>
        </footer>

      </div>
    </>
  )
}

export default function App() {
  return (
    <ThemeProvider theme={psiTheme}>
      <Authenticator.Provider>
        {/* <Authenticator components={components}>
        {({ signOut, user }) => ( */}
        <AppAuthenticated />
        {/* )} */}
        {/* </Authenticator> */}
      </Authenticator.Provider>
    </ThemeProvider>
  )
}
