/**
 * Properties of a Daily Report response.
 */

import { array, InferType, number, object, string } from "yup"

export const DailyReportRowSchema = object({
  Datum: string().required(),
  "Uhrzeit (UTC)": string().required(),
  "Datum (Local)": string().optional(),
  "Uhrzeit (Local)": string().optional(),
  Entleerungszeit: number().required(),
  Zykluszeit: number().required(),
  Handbefuellungen: number().required(),
  WagenNummer: string().optional(),
  Fehlermeldung: string().required(),
})

export const DailyReportResponseSchema = object({
  dateOfReport: string().required(),
  rows: array(DailyReportRowSchema).required()
})

export type DailyReportRowDto = InferType<typeof DailyReportRowSchema>
export type DailyReportResponseDto = InferType<typeof DailyReportResponseSchema>

type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};
type drRowDto = Prettify<DailyReportRowDto>
type drResponseDto = Prettify<DailyReportResponseDto>
