import * as React from 'react'
import { useParams } from 'react-router-dom'
// import './reports.css'

import { isDev } from '../config'
import { Breadcrumbs, getLinkToMaschineBaleStats } from '../LinkUtils'
import { fetchFromApi } from '../api/utils'
import ConnectivityIndicator from '../components/ConnectivityIndicator'

interface BaleReportsListProps {
  ext_sn: string
  numDays?: number
  [key: string]: any
}
export function BaleReportsList({ ext_sn, numDays }: BaleReportsListProps) {
  const [data, setData] = React.useState<any | null>(null)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<string | null>(null)

  React.useEffect(() => {
    const fetchData = async (ext_sn: string) => {
      try {
        const apiName = 'maschinenApi'
        const path = `/maschine/${ext_sn}/reports/bales?numDays=${numDays}`
        const reports = await fetchFromApi(apiName, path) as any
        if (!reports || !reports.latest) {
          throw new Error(`Keine Daten für ${ext_sn} gefunden.`)
        }
        setData(reports)
        setError(null)
      } catch (err) {
        console.error('Error:', err)
        setData(null)
        if (err instanceof Error) {
          setError(err.message)
        } else {
          setError((err as any).toString())
        }
      } finally {
        setLoading(false)
      }
    }
    fetchData(ext_sn)
  }, [ext_sn, numDays])

  if (loading) {
    return <div>Lade Daten...</div>
  }
  if (error) {
    return <div>{`Fehler beim Laden der Daten - ${error}`}</div>
  }
  const maschineDummy = {
    ext_sn: ext_sn
  }
  const reportsMapped = data.latest.map((baleRecord: any) => {
    const dateHuman = new Date(baleRecord.date).toDateString()
    const link = getLinkToMaschineBaleStats(maschineDummy, baleRecord.date)
    const anzahlPresszyklen = Number(baleRecord.anzahl_presszyklen?.N) || -1
    return <li key={baleRecord.date}>
      <a href={link}>Ballen {baleRecord.baleNr} am {dateHuman}</a>
      {baleRecord.ejectionType.startsWith("before_bf")
        && <span style={{ fontSize: '11px' }}> <ConnectivityIndicator isConnected={false} name={'Vorzeitiger Auswurf'} /></span>}
      {anzahlPresszyklen > 0 && anzahlPresszyklen < 5
        && <span style={{ fontSize: '11px' }}> <ConnectivityIndicator isConnected={true} isDisabled={true} name={`Low PZ: ${anzahlPresszyklen}`} /></span>}
      {anzahlPresszyklen > 95
        && <span style={{ fontSize: '11px' }}> <ConnectivityIndicator isConnected={false} name={`High PZ: ${anzahlPresszyklen}`} /></span>}
    </li>
  })
  return <ul>{reportsMapped}</ul>
}

export default function BaleReports() {
  const params = useParams<{ seriennummer: string | undefined }>()
  const ext_sn = params.seriennummer

  if (!ext_sn) {
    return <div>Fehler beim Laden der Ballenberichte. Keine Maschinennummer angegeben.</div>
  }

  const numDays = isDev
    ? 900
    : 30
  return <div style={{ display: 'flex' }}>
    <main style={{ padding: '0rem 1rem' }}>
      <h2>Ballenberichte für {ext_sn}</h2>
      <Breadcrumbs seriennummer={ext_sn} level1='reports' />

      <p>Hier finden Sie Ballenberichte für die letzten {numDays} Tage für die Maschine {ext_sn}. Durch Anklicken wird der Bericht geöffnet.</p>

      <BaleReportsList ext_sn={ext_sn} numDays={numDays} />
    </main>
  </div>
}
