import { MachineEntry, secondsToString } from 'psi-lib'

interface StatusDetailsProps {
  maschine: MachineEntry
  [key: string]: any
}
export default function StatusDetails({ maschine }: StatusDetailsProps) {
  const deviceState = maschine.deviceState
  if (deviceState && deviceState.anzahlBallen && deviceState.betriebsStunden) {
    return (
      <div>
        <strong>Kennzahlen</strong><br />
        Anzahl Ballen: {deviceState.anzahlBallen}<br />
        Betriebsstunden: {secondsToString(deviceState.betriebsStunden)}<br />
      </div>
    )
  }
  if (maschine.ext_sn && maschine.ext_sn.startsWith('411.')) {
    return (
      <div>
        <strong>Kennzahlen</strong><br />
        <div>Für ALB ready Maschinen können keine Kennzahlen angezeigt werden.</div>
      </div>
    )
  }
  return (
    <div>
      <strong>Kennzahlen</strong><br />
      <div>Für diese Maschine können keine Kennzahlen angezeigt werden.</div>
    </div>
  )
}

